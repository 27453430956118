<template>
  <div class="processServices-home-page">
    <div class="carousel-box">
      <el-carousel height="418px">
        <el-carousel-item v-for="(item, index) in detailsObj.bannerImgArr" :key="index">
          <img :src="$fileUrl+item" alt="" @click="viewImg($fileUrl+item)">
        </el-carousel-item>
      </el-carousel>
      <div class="brief-introduction">
        <p class="company-name">
          {{ detailsObj.cpmName }}
        </p>
        <div class="authentication">
          <img class="locate" src="@/assets/icon/locate.png" alt="">
          {{ detailsObj.factoryProvinceName + detailsObj.factoryCityName }}
          <img class="pass-through" src="@/assets/icon/pass_through.png" alt="">
        </div>
        <div class="describe">
          <p class="label">
            主营工艺：
          </p>
          <p class="value">
            {{ (detailsObj.technology.map((item) => (getDictName($store.getters.getDictionaryItem('FACTORY_TECHNOLOGY'), item)))).join('、') }}
          </p>
        </div>
        <div class="describe">
          <p class="label">
            提供服务：
          </p>
          <p class="value">
            {{ (detailsObj.service.map((item) => (getDictName($store.getters.getDictionaryItem('FACTORY_SERVICE_TYPE'), item)))).join('、') }}
          </p>
        </div>
        <div class="describe">
          <p class="label">
            合作模式：
          </p>
          <p class="value">
            {{ (detailsObj.cooperationMode.map((item) => (getDictName($store.getters.getDictionaryItem('FACTORY_COOPERATION_MODE'), item)))).join('、') }}
          </p>
        </div>
        <div class="describe">
          <p class="label">
            厂房地址：
          </p>
          <p class="value">
            {{ detailsObj.factoryProvinceName + detailsObj.factoryCityName + detailsObj.factoryRegionName + detailsObj.factoryAddressDesc }}
          </p>
        </div>
        <div class="contact">
          <el-tooltip class="item" effect="dark" content="Top Left 提示文字" placement="top-start">
            <div slot="content">
              联系人：{{ detailsObj.contactPerson }}  {{
                detailsObj.contactPhone
              }}
            </div>
            <p class="contact-information">
              联系TA
            </p>
          </el-tooltip>
        </div>
      </div>
    </div>
    <ul class="process-data">
      <li v-for="(item) in list" :key="item.label">
        <img :src="item.icon" alt="">
        <div>
          <p class="value">
            <span class="number">
              {{ item.num }}
            </span>{{ item.unit }}
          </p>
          <p class="label">
            {{ item.label }}
          </p>
        </div>
      </li>
    </ul>
    <!-- 加工产品 -->
    <div class="product-box">
      <h3 class="small-title">
        加工产品
      </h3>
      <div class="product-describe">
        产品用途：
        <span class="value">{{ (detailsObj.productUse.map((item) => (getDictName($store.getters.getDictionaryItem('FACTORY_PRODUCT_USE'), item)))).join('、') }}</span>
      </div>
      <div class="product-describe">
        加工原料：
        <span class="value">{{ (detailsObj.material.map((item) => (getDictName($store.getters.getDictionaryItem('FACTORY_MATERIAL'), item)))).join('、') }}</span>
      </div>
      <div class="carousel-main">
        <div class="process-banner">
          <p class="label">
            加工<br>产成品
          </p>
          <p class="sub-label">
            精选保障业务
          </p>
        </div>
        <el-carousel trigger="click" height="362px" :autoplay="false">
          <el-carousel-item v-for="item in Math.ceil((detailsObj.tradeFactoryProductInfoList.length || 0) / 4)" :key="item">
            <div class="card-main">
              <div v-for="cl in detailsObj.tradeFactoryProductInfoList.slice((item - 1) * 4, item * 4)" :key="cl.id" class="card-box">
                <el-carousel height="280px" trigger="click" class="item-img-carousel">
                  <el-carousel-item v-for="bl in cl.productImgList" :key="bl">
                    <img class="img-box" :src="$fileUrl+bl" alt="" @click="viewImg($fileUrl+bl)">
                  </el-carousel-item>
                </el-carousel>
                <!-- <img class="img-box" :src="$fileUrl+cl.productImgUrlOne" alt=""> -->
                <div class="material beyond-hide-1">
                  {{ cl.productName }}
                </div>
                <!-- <div class="inquiry" @click="clickInquiry">
                  下单询价
                </div> -->
              </div>
            </div>
          </el-carousel-item>
        </el-carousel>
      </div>
    </div>
    <!-- 设备信息 -->
    <div class="device-info">
      <h3 class="small-title">
        设备信息
      </h3>
      <Table
        :item-data="itemData"
        :list-data="detailsObj.tradeFactoryDeviceInfoList"
      >
        <template #deviceQuantityHeader>
          <div>设备数量{{ '(共' + detailsObj.deviceQuantityTatol + '台)' }}</div>
        </template>
      </Table>
    </div>
    <el-dialog
      :visible.sync="dialogVisible"
      title="预览"
      width="50%"
    >
      <img class="preview" fit="contain" :src="dialogImageUrl" alt="">
    </el-dialog>
  </div>
</template>

<script>
import Table from '@/components/Table.vue'
import { managerQueryFactoryInfoDesc } from '@/api/processingServices'
import { getDictName } from '@/utils/util'
export default {
  components: {
    Table
  },
  data() {
    return {
      dialogVisible: false,
      dialogImageUrl: '',
      getDictName,
      detailsObj: {
        technology: [],
        service: [],
        cooperationMode: [],
        productUse: [],
        material: [],
        tradeFactoryProductInfoList: []
      },
      list: {
        a: {
          num: 0,
          unit: '年',
          label: '成立年限',
          icon: require('@/assets/icon/time_icon.png')
        },
        b: {
          num: 0,
          unit: '人',
          label: '工人数量',
          icon: require('@/assets/icon/worker_icon.png')
        },
        c: {
          num: 0,
          unit: '条',
          label: '流水线',
          icon: require('@/assets/icon/pipeline_icon.png')
        },
        d: {
          num: '',
          label: '厂房归属',
          icon: require('@/assets/icon/workshop_icon.png')
        },
        e: {
          num: 0,
          unit: '㎡',
          label: '厂房面积',
          icon: require('@/assets/icon/area_icon.png')
        }
      },
      itemData: [
        { label: '设备名称 ', prop: 'deviceName', width: 180 },
        { label: '设备数量 ', prop: 'deviceQuantity', header: 'deviceQuantityHeader', width: 180 },
        { label: '设备来源', prop: 'deviceSource', width: 120, child: this.$store.getters.getDictionaryItem('FACTORY_DEVICE_SOURCE') },
        { label: '设备类型', prop: 'deviceType', width: 120, child: this.$store.getters.getDictionaryItem('FACTORY_DEVICE_TYPE') },
        { label: '品牌 ', prop: 'deviceBrand', width: 120 },
        { label: '型号 ', prop: 'deviceModel', width: 120 },
        { label: '投产年限 ', prop: 'productionYear', width: 120 }
      ],
      tableListData: [],
      basicInformation: {}
    }
  },
  mounted() {
    if (this.$route.query.id) {
      managerQueryFactoryInfoDesc(this.$route.query.id, res => {
        this.detailsObj = { ...res.data }
        this.detailsObj.bannerImgArr = res.data.workshopImg.concat(res.data.deviceImg)
        this.list.a.num = res.data.establishYear
        this.list.b.num = res.data.workerNum
        this.list.c.num = res.data.lineNum
        this.list.d.num = res.data.factoryOwner === '01' ? '租赁' : '自有'
        this.list.e.num = res.data.factoryArea
        const tatol = this.detailsObj.tradeFactoryDeviceInfoList.reduce((prev, current) => prev + (current.deviceQuantity || 0), 0)
        this.detailsObj.deviceQuantityTatol = tatol
      })
    }
  },
  methods: {
    // clickInquiry() {
    //   this.$router.push({
    //     path: `/processingServices/inquiry?id=${this.detailsObj.id}`
    //   })
    // }
    // 查看大图
    viewImg(url) {
      this.dialogVisible = true
      this.dialogImageUrl = url
    }
  }
}
</script>

<style lang="scss" scoped>
.processServices-home-page {
  padding: 34px 64px;
  background-color: #fff;

  .carousel-box {
    display: flex;
    justify-content: space-between;

    .el-carousel {
      flex: 1;
      margin-right: 20px;
      img {
        width: 100%;
        height: 100%;
        cursor: pointer;
      }
    }

    .brief-introduction {
      width: 340px;
      height: 418px;
      background: linear-gradient(180.8deg, rgba(1, 114, 235, 0.3) 0%, rgba(1, 114, 235, 0.04) 21%, rgba(255, 255, 255, 1) 100%);
      padding: 24px 24px 0;
      box-sizing: border-box;

      .company-name {
        font-size: 18px;
        font-weight: bold;
      }

      .authentication {
        display: flex;
        align-items: center;
        color: rgb(51, 51, 51);
        font-size: 14px;
        margin-top: 5px;

        .locate {
          width: 20px;
          height: 20px;
        }

        .pass-through {
          width: 108px;
          height: 20px;
          margin-left: 12px;
        }
      }

      .describe {
        margin-top: 16px;
        .label {
          color: rgb(153, 153, 153);
          font-size: 14px;
          margin-bottom: 4px;
        }
        .value {
          color: rgb(51, 51, 51);
          font-size: 14px;
        }
      }

      .contact {
        background-color: blue;
        width: 100%;
        height: 32px;
        line-height: 32px;
        text-align: center;
        color: #fff;
        margin-top: 20px;
        cursor: pointer;
      }
    }
  }

  .process-data {
    display: flex;
    justify-content: space-between;
    background-color: #fff;
    margin: 16px 0;
    li {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 16.66%;
      height: 104px;
    }
    img {
      width: 56px;
      height: 56px;
    }
    .value {
      font-size: 14px;
      color: #333;
    }
    .number {
      font-size: 24px;
      font-weight: bold;
    }
    .label {
      font-size: 12px;
      color: #666;
    }
  }

  // 加工产品
  .product-box {
    background-color: #fff;
    padding: 24px;
    .product-describe {
      color: #999999ff;
      font-size: 14px;
      margin-bottom: 8px;
      .value {
        color: #333333;
        font-size: 14px;
      }
    }

    .carousel-main {
      display: flex;
      justify-content: space-between;
      margin-top: 24px;
      .process-banner {
        width: 276px;
        height: 362px;
        background: url('../../../assets/icon/process.png') no-repeat top center;
        background-size: 100% 100%;
        padding: 56px 28px;
        box-sizing: border-box;
        .label {
          font-size: 52px;
          color: #000;
          font-weight: bold;
        }
        .sub-label{
          color: #E85F3B;
          font-size: 20px;
          letter-spacing: 8px;
          margin-top: 16px;
        }
      }

      .el-carousel {
        flex: 1;
      }
    }

    .card-main {
      display: flex;
      justify-content: flex-start
    }
    .card-box {
      width: 276px;
      height: 362px;
      background: #fff;
      padding: 16px;
      box-sizing: border-box;
      border: 1px solid #e7e7e7ff;
      margin-left: 16px;
      .item-img-carousel {
        width: 244px;
        /deep/ .el-carousel__arrow {
          display: none !important;
        }
      }
      .img-box {
        width: 244px;
        height: 280px;
        cursor: pointer;
      }
      .material {
        font-size: 16px;
        font-weight: bold;
        margin-top: 16px;
      }
      .inquiry {
        color: red;
        width: 99px;
        height: 32px;
        line-height: 32px;
        text-align: center;
        border: 1px solid red;
        margin-top: 16px;
        cursor: pointer;
      }
    }
  }

  // 设备信息
  .device-info {
    padding: 24px;
    background-color: #fff;
    margin-top: 16px;
  }

  .small-title {
    font-size: 20px;
    font-weight: 500;
    margin-bottom: 16px;
  }
}
.preview {
   width: 100%;
  }
</style>
