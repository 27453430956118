import { render, staticRenderFns } from "./processManageDetails.vue?vue&type=template&id=493d5ed9&scoped=true&"
import script from "./processManageDetails.vue?vue&type=script&lang=js&"
export * from "./processManageDetails.vue?vue&type=script&lang=js&"
import style0 from "./processManageDetails.vue?vue&type=style&index=0&id=493d5ed9&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "493d5ed9",
  null
  
)

export default component.exports